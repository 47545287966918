// DETALJ

#detalj {

}
#detalj-top {
  position: relative;
  /*  .row {
      @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        margin-left: -8px;
        margin-right: -8px;
      }
    }*/
  #product-image {
    @media (max-width: 479px) {
      width: 100%;
    }
    //text-align: center;
  }
  #product-info {
    position: static;
    @media (max-width: 479px) {
      width: 100%;
    }
    h2 {
      font-size: 21px;
      font-size: 2.1rem;
      font-weight: bold;
      margin-bottom: 5px;
      @media (min-width: $screen-phone) {
        margin-top: 0;
      }
      @media (min-width: $screen-tablet) {
        margin-top: 0;
      }
      @media (min-width: $screen-desktop) {
        margin-bottom: 10px;
      }
    }
    .addthis_toolbox {
      background-color: #E3F6FF;
      border-radius: 0.2em 0.2em 0.2em 0.2em;
      float: left;
      margin-bottom: 2%;
      padding: 1%;
      .addthis_counter.addthis_bubble_style {
        width: 36px !important;
      }
    }
    p {
      clear: left;
      @include font-size(1.5);
      //line-height: 1.4;
      margin-bottom: 3.2%;
      @media (min-width: $screen-tablet) {
        @include font-size(1.3);
      }
      @media (min-width: $screen-desktop) {
        @include font-size(1.6);
      }
      @media (min-width: $screen-lg-desktop) {
        @include font-size(1.7);
      }
    }
    .zatrazite-button {
      @extend .btn;
      @include btn-pseudo-states-gradient(#0A191D, #E2F6FF, #C5EDFF, #FFFFFF, #88DDFF);
      margin-right: 5px;
      margin-top: 10px;
      padding: 1px 22px 4px;
      @include font-size(1.6);
      color: #0A191D;
      line-height: 1.5;
      position: absolute;
      bottom: 1px;
      @media (min-width: $screen-tablet) {
        @include font-size(1.5);
        padding: 0px 12px 2px;
      }
      @media (min-width: $screen-desktop) {
        @include font-size(1.8);
        padding: 0px 22px 4px;
        line-height: 1.3;
      }
      @media (min-width: $screen-lg-desktop) {
        @include font-size(1.9);
        padding: 0px 22px 4px;
        line-height: 1.42;
      }
      span {
        color: #00A4E3;
        @include font-size(2.2);
        margin-right: 15px;
        top: 5px;
        @media (min-width: $screen-tablet) {
          @include font-size(1.7);
          margin-right: 10px;
          top: 3px;
        }
        @media (min-width: $screen-desktop) {
          @include font-size(2.0);
          margin-right: 15px;
          top: 4px;
        }
        @media (min-width: $screen-lg-desktop) {
          @include font-size(2.2);
          margin-right: 15px;
          top: 5px;
        }
      }
    }
  }
}
#povezani {
  margin-top: 14px;
  @media (min-width: $screen-tablet) {
    margin-top: 28px;
  }
  & > div.portfolio_description_povezani {
    @include clearfix();
    margin-bottom: 10px;
    background-color: #DFF5FF;
    padding-top:10px;
    padding-bottom:10px;
    @media (max-width: 479px) {
      width:100%
    }
    @media (min-width: $screen-phone) {
      margin-bottom: 0;
      background-color: #FFF;
    }
    @media (min-width: $screen-tablet) {
      background-color: #FFF;
      padding-top:0;
      padding-bottom:0;
    }
  }
  h2 {
    @include font-size(1.8);
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 18px;
    padding-top:20px;
    border-top: 2px dashed #EF3F6A;
    @media (min-width: $screen-phone) {
      @include font-size(1.9);
      margin-bottom: 6px;
    }
    @media (min-width: $screen-tablet) {
      @include font-size(1.9);
      margin-bottom: 18px;
    }
    @media (min-width: $screen-desktop) {
      @include font-size(2.1);
    }
  }
  h3 {
    margin-top: 14px;
    line-height: 0.9;
    float:left;
    max-width: 60%;
    @media (min-width: $screen-phone) {
      margin-top: 8px;
      max-width: 100%;
    }
    @media (min-width: $screen-tablet) {
      margin-top: 8px;
      max-width: 100%;
    }
    a{
      color: #293A6F;
      @include font-size(1.7);
      text-decoration: none;
      @media (min-width: $screen-phone) {
        @include font-size(1.7);
      }
      @media (min-width: $screen-tablet) {
        @include font-size(1.4);
      }
      @media (min-width: $screen-desktop) {
        @include font-size(1.7);
      }
      @media (min-width: $screen-lg-desktop) {
        @include font-size(1.8);
      }
      &:hover{
        color: #949494;
      };
    }
  }
  .thumbnail {
    width:30%;
    float:left;
    padding:4px;
    margin-right: 10px;
    @media (min-width: $screen-phone) {
      width:100%;
      margin-right: 0;
    }
    @media (min-width: $screen-tablet) {
      width:100%;
      margin-right: 0;
    }
  }
}
