// HEADER 1

$header1-top-color:       #E2F6FF;
$header1-bottom-color:    #C5EDFF;
$header2-top-border: 1px #5FCEFF solid;
$header2-bottom-border: 1px solid #5FCEFF;

#header1 {
  background: linear-gradient(to bottom, $header1-top-color, $header1-bottom-color);
  //#gradient .vertical(@header1-top-color, @header1-bottom-color);
  //border-bottom: @header2-bottom-border;
  //margin-bottom: 30px;
  color: #273A76;
  padding: 5px 0 10px;
  @media (min-width: $screen-tablet) {
    padding: 25px 0 12px;
  }
  #logo {
    margin-top: 5px;
    img {
      @include center-block();
      width: 100%;
    }
  }
  h2 {
    @include font-size(3.2);
    span {
      @include font-size(4.1);
    }
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    line-height: 1.2;
    @media (min-width: $screen-phone) {
      @include font-size(4.5);
      span {
        display: block;
        @include font-size(5.5);
      }
    }
    @media (min-width: $screen-tablet) {
      @include font-size(3.1);
      span {
        @include font-size(4.1);
      }
      margin-top: 10px;
    }
    @media (min-width: $screen-desktop) {
      @include font-size(4.0);
      span {
        @include font-size(5.5);
      }
      margin-top: 17px;
    }
    @media (min-width: $screen-lg-desktop) {
      @include font-size(4.9);
      span {
        @include font-size(6.1);
      }
      margin-top: 22px;
    }
  }
  h3 {
    img {
      width: 16%;
      margin-right: 5px;
      margin-top: -5px;
    }
    @include font-size(3.2);
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    @media (min-width: $screen-phone) {
      img {
        width: 12%;
      }
      @include font-size(4.0);
    }
    @media (min-width: $screen-tablet) {
      img {
        width: 12%;
      }
      @include font-size(3.3);
      margin-top: 12px;
    }
    @media (min-width: $screen-desktop) {
      img {
        width: 17%;
      }
      @include font-size(4.4);
      margin-top: 22px;
    }
    @media (min-width: $screen-lg-desktop) {
      img {
        width: 17%;
      }
      font-size: 54px;
      font-size: 5.4rem;
      margin-top: 32px;
    }
  }
  .items{
    display: table;
    margin: 0 auto;
    //position: absolute;
    text-align: center;
    width:100%;
    p {
      margin-right: 4.3%;
      color: #7d7d7d;
      text-shadow: #FFF 0 0 3px;
      font-size: 19px;
      font-size: 1.9rem;
      display: inline-block;
      line-height: 1.0;
      span {
        color:green;
        text-shadow: #FF5 0 0 3px;
        margin-right: 6px;
      }
    }
  }
}