@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 700;
  src: local('Yanone Kaffeesatz Bold'), local('YanoneKaffeesatz-Bold'), url(http://themes.googleusercontent.com/static/fonts/yanonekaffeesatz/v4/We_iSDqttE3etzfdfhuPRWwXwFHHZlCwprwJOQ-Se7k.woff) format('woff');
}

$icon-font-path: "../../fonts/";
$image-path: "../img/";

// Button psuedo states
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin btn-pseudo-states-gradient($color, $top, $bottom, $third, $border) {
  color: $color;
  background: linear-gradient(to bottom, $top, $bottom);
  border-color: $border;

  &:hover, &:focus {
    background: linear-gradient(to bottom, $third, $bottom);
    color: $color;
  }

  &:active, &.active {
    background: linear-gradient(to bottom, $bottom, $third);
    color: $color;
  }
}

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}