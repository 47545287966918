// POCETNA

.ponuda, .preporuka, .akcija {
  background-color: #FFFFB7;
  border-radius: 10px;
  margin-bottom: 15px;
  text-align: center;
  @media (min-width: $screen-sm) {
    min-height:270px;
  }
  @media (min-width: $screen-md) {
    min-height:255px;
  }
  @media (min-width: $screen-lg) {
    min-height:310px;
  }
  h2 {
    color: #fff;
    border-radius: 4px;
    margin: 0;
    padding: 6px 20px 5px;
    @include font-size(1.8);
    font-weight: bold;
    @media (min-width: $screen-xs) {
      text-align: left;
    }
    @media (min-width: $screen-md) {
      border-radius: 10px 10px 0 0;
      padding: 9px 20px 8px;
      @include font-size(2.0);
    }
  }
  > div {
    padding: 7%;
    @media (min-width: $screen-xs) {
      padding: 20px 15px;
    }
    @media (min-width: $screen-sm) {
      padding: 7%;
    }
  }
}

// PONUDA

.ponuda {
  h2 {
    background-color: #F15A22;
  }
  h3 {
    margin: 0;
    @include font-size(1.9);
    //font-weight: bold;
    @media (min-width: $screen-xs) {
      @include font-size(2.6);
    }
    @media (min-width: $screen-sm) {
      @include font-size(1.6);
    }
    @media (min-width: $screen-md) {
      @include font-size(1.8);
    }
    @media (min-width: $screen-lg) {
      @include font-size(2.0);
    }
  }
  p.cijena {
    background-color: #FFFFFF;
    border-radius: 12px;
    color: #FF0000;
    @include font-size(3.5);
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 20px;
    padding: 2px 10px;
    @media (min-width: $screen-xs) {
      @include font-size(4.8);
    }
    @media (min-width: $screen-sm) {
      @include font-size(2.7);
    }
    @media (min-width: $screen-md) {
      @include font-size(3.0);
      margin-top: 10px;
    }
    @media (min-width: $screen-lg) {
      @include font-size(4.0);
      padding: 10px 10px;
    }
  }
  p.zvjezdica {
    text-align: left;
    @include font-size(1.4);
    margin-bottom: 0;
  }
}

// PREPORUKA

.preporuka {
  h2 {
    background-color: red;
  }
  h3 {
    margin-top: 15px;
    margin-bottom: 0;
    @include font-size(2.0);
    //font-weight: bold;
    @media (min-width: $screen-xs) {
      @include font-size(2.3);
    }
    @media (min-width: $screen-sm) {
      @include font-size(2.0);
    }
    @media (min-width: $screen-md) {
      @include font-size(1.7);
    }
    @media (min-width: $screen-lg) {
      @include font-size(2.1);
      margin-top: 18px;
    }
  }
  .thumbnail {
    max-width: 70%;
    margin: 0 auto;
    @media (min-width: $screen-xs) {
      padding: 6px;
      max-width: 42%;
    }
    @media (min-width: $screen-sm) {
      max-width: 90%;
      padding: 4px;
    }
    @media (min-width: $screen-md) {
      max-width: 70%;
      padding: 4px;
    }
    @media (min-width: $screen-lg) {
      max-width: 75%;
      padding: 4px;
    }
  }
}

// AKCIJA

.akcija {
  h2 {
    background-color: #C60101;
  }
  #akcija-image {
    @include clearfix();
    @media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
      padding: 20px 35px;
    }
    h3.title-sm {
      float: left;
      width: 100%;
      @include font-size(1.9);
      margin-top: 0;
      margin-bottom: 5px;
      //font-weight: bold;
    }
    a.thumbnail {
      float:left;
      max-width: 35%;
      margin-right: 5%;
      @media (min-width: $screen-xs) {
        max-width: 25%;
        margin-right: 7%
      }
      @media (min-width: $screen-sm) {
        max-width: 33%;
        margin-right: 4%;
      }
    }
    h3.title {
      float: left;
      text-align: center;
      width: 60%;
      @include font-size(2.0);
      margin-top: 8px;
      font-weight: bold;
      @media (min-width: $screen-xs) {
        @include font-size(2.2);
        width: 66%;
        margin-top: 10px;
      }
      @media (min-width: $screen-md) {
        @include font-size(1.7);
        width: 63%;
        margin-top: 0px;
      }
      @media (min-width: $screen-lg) {
        @include font-size(2.2);
      }
    }
    h3.cijena {
      float: left;
      color: #FF0000;
      width: 60%;
      @include font-size(3.0);
      font-weight: bold;
      margin-top: 0px;
      @media (min-width: $screen-xs) {
        width: 66%;
        @include font-size(5.1);
        margin-bottom: 18px;
        margin-top: 14px;
      }
      @media (min-width: $screen-sm) {
        width: 63%;
        @include font-size(2.3);
        margin-bottom: 0px;
        margin-top: 0px;
      }
      @media (min-width: $screen-md) {
        width: 63%;
        @include font-size(3.0);
        margin-bottom: 0px;
        margin-top: 0px;
      }
      @media (min-width: $screen-lg) {
        width: 63%;
        @include font-size(3.6);
        margin-bottom: 14px;
        margin-top: 0px;
      }
    }
    p#grafika {
      float: left;
      width: 60%;
      @include font-size(1.6);
      @media (min-width: $screen-xs) {
        width: 66%;
        @include font-size(1.7);
      }
      @media (min-width: $screen-sm) {
        width: 56%;
        @include font-size(1.5);
      }
      @media (min-width: $screen-md) {
        width: 63%;
        @include font-size(1.6);
        margin-bottom: 7px;
        margin-top: 8px;
      }
      @media (min-width: $screen-lg) {
        width: 63%;
        @include font-size(1.8);
        margin-bottom: 10px;
        margin-top: 0px;
      }
    }
    p.zvjezdica {
      text-align: left;
      @include font-size(1.4);
      margin-bottom: 0;
      margin-top: 10px;
      clear: left;
      float: left;
      width: 100%;
      @media (min-width: $screen-xs) {
        @include font-size(1.4);
        margin-top: 15px;
      }
      @media (min-width: $screen-sm) {
        @include font-size(1.3);
        margin-top: 12px;
      }
      @media (min-width: $screen-md) {
        @include font-size(1.4);
        margin-top: 10px;
      }
      @media (min-width: $screen-lg) {
        @include font-size(1.3);
        margin-top: 16px;
      }
    }

  }
}