// NAVBAR

$header1-top-color:       #050E10;
$header1-bottom-color:    #193843;
$header1-border: 1px #5FCEFF solid;

// Navbar links
$navbar-link-color:                #FCFCFC;
$navbar-link-hover-color:          #5FCEFF;
$navbar-link-active-color:         #5FCEFF;
$navbar-link-bg-hover:             transparent;
$navbar-link-active-bg:            transparent;

/* @media (min-width: @grid-float-breakpoint) {
   @navbar-link-color:                #FCFCFC;
   @navbar-link-hover-color:          #0A191D;
   @navbar-link-active-color:         #0A191D;
   @navbar-link-bg-hover:             #E0F5FF;
   @navbar-link-active-bg:            #E0F5FF;
} */

#navbar-wrapper {
  //#gradient .vertical(@header1-top-color, @header1-bottom-color);
  background-color: $header1-top-color;
  border-bottom: $header1-border;
  @include font-size(1.4);
  font-weight: bold;
  margin-bottom: 0;
  @media (min-width: $screen-tablet) {
    padding: 12px 0;
  }
  @media (min-width: $screen-desktop) {
    @include font-size(1.5);
    padding: 12px 0;
  }
  a {
    transition: color 0.3s linear 0s;
  }
  .navbar-toggle {
    margin-top: 15px;
  }
  .navbar-brand {
    color: #fafafa;
    width: 250px;
    img {
      width: 100%;
    }
  }
  .navbar-collapse {
    border-color: darken(#050E10, 7%);
  }
  .navbar-right {
    margin-top: 4px;
    a {
      float: right;
    }
  }
  .navbar-nav {
    > li {
      margin-right: 10px;
    }
    > li > a {
      color: $navbar-link-color;
      @media (min-width: $screen-tablet) {
        border-radius: 4px;
        padding: 8px 10px;
      }
      @media (min-width: $screen-desktop) {
        padding: 10px 15px;
      }
      &:hover,
      &:focus {
        color: $navbar-link-hover-color;
        background-color: $navbar-link-bg-hover;
        @media (min-width: $grid-float-breakpoint) {
          color: #0A191D;
          background: linear-gradient(to bottom, #E3F6FF, #BBEAFF);
          //#gradient .vertical(#E3F6FF, #BBEAFF);
        }
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-link-active-color;
        background-color: $navbar-link-active-bg;
        @media (min-width: $grid-float-breakpoint) {
          color: #0A191D;
          background: linear-gradient(to bottom, #E3F6FF, #BBEAFF);
          //#gradient .vertical(#E3F6FF, #BBEAFF);
        }
      }
    }
  }
  @media (min-width: $grid-float-breakpoint) {
    li:hover > ul {
      display: block;
    }
    li:hover > a {
      background: linear-gradient(to bottom, #E3F6FF, #BBEAFF);
      //#gradient .vertical(#E3F6FF, #BBEAFF);
      color: #0A191D;
    }
    li.dropdown:after {
      content:'\00A0';
      position: absolute;
      width: 100%;
    }
    ul li:first-child > a {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    ul li:last-child > a {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .dropdown-menu li > a {
      font-weight: bold;
      @include font-size(1.4);
      color: $navbar-link-color;
      @media (min-width: $screen-tablet) {
        padding: 8px 10px;
      }
      @media (min-width: $screen-desktop) {
        @include font-size(1.5);
        padding: 9px 15px;
      }
    }
    .dropdown-menu li > a:hover,
    .dropdown-menu li > a:focus {
      background: linear-gradient(to bottom, #E3F6FF, #BBEAFF);
      //#gradient .vertical(#E3F6FF, #BBEAFF);
      color: #0A191D;
    }
    .dropdown-menu {
      padding: 0;
      top: 120%;
      width:120%;
      background-color: #112931;
      border-radius: 8px;
    }
  }
}