#o-nama {
  h2{
    color: #293A6F;
    font-size: 24px;
    font-size: 2.4rem;
    font-weight: bold;
    padding-bottom:15px;
    border-bottom: 2px solid #EF3F6A;
  }
  p{
    font-size: 17px;
    font-size: 1.7rem;
  }
}