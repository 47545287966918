// GDJE

#gdje h3 {
  font-weight:normal;
  @include font-size(1.7);
  @media (min-width: $screen-tablet) {
    @include font-size(1.7);
  }
  @media (min-width: $screen-desktop) {
    @include font-size(1.9);
  }
  line-height: 1.42857;
  padding-bottom:20px;
  margin-bottom:30px;
  border-bottom: 1px dashed #EF3F6A;
}

#map_canvas {
  margin-bottom: 20px;
  #info {
    p {
      margin:10px 0 10px 9px;
      font-size: 14px;
    }
    h3 {
      margin:0 0 10px 9px;
    }
    img {
      margin-left:9px;
    }
  }
}

#adresa_form {
  margin-bottom:20px;
  #adresa {
    border: 1px solid #CBCBCB;
    color: #4D4D4D;
    @include font-size(1.7);
    margin-bottom:14px;
    padding: 7px;
    width: 100%;
    border-radius: 4px;
  }
  label {
    @include font-size(1.7);
    display:block;
    margin-bottom:8px;
  }
  #gdje-button {
    @extend .btn;
    @include btn-pseudo-states-gradient(#0A191D, #E2F6FF, #C5EDFF, #FFFFFF, #88DDFF);
    margin-right: 5px;
    margin-top: 10px;
    padding: 7px 22px;
    @include font-size(1.6);
    color: #0A191D;
    @media (min-width: $screen-tablet) {
      @include font-size(1.7);
    }
    @media (min-width: $screen-desktop) {
      @include font-size(1.7);
      line-height: 1.3;
    }
    @media (min-width: $screen-lg-desktop) {
      @include font-size(1.8);
    }
  }
}
#directions {
  margin-bottom:14px;
}
