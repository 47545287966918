#comments{
  float:left;
  width:100%;
  h4{
    border-bottom: 1px solid #5FCEFF;
    border-top: none;
    color: #EF3F6A;
    font-size: 22px;
    font-weight:normal;
    float:left;
    width:100%;
    margin-bottom:30px;
    padding-bottom: 5px;
  }
}

#existing-comments{
  @include clearfix();
}

.comment{
  float:left;
  clear:left;
  width:100%;
  margin-bottom:25px;
  .col-sm-2 {
    width: 12.5%;
  }
  .col-sm-10 {
    width: 87.5%;
  }
  .image{
    float:left;
    img {
      width: 100%;
    }
  }
  .details{
    float:left;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color:#eee;
    .name, .date{
      background-color: #F6FBFF;
      float: left;
      font-family: Arial,Helvetica,sans-serif;
      margin-bottom: 15px;
      margin-right: 30px;
      padding: 8px 15px;
    }
    p{
      margin-bottom: 0;
    }
    .content{
      font-family: Arial,Helvetica,sans-serif;
      padding:15px;
      float:left;
      width:100%;
      background: none repeat scroll 0 0 #FFF;
    }
  }
}