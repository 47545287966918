// KONTAKT

.kontakt{
  .adresa {
    h4 {
      @include font-size(1.8);
      font-weight: bold;
    }
    p {
      @include font-size(1.5);
      line-height: 28px;
    }
    @media (max-width: 480px) {
      width:100%
    }
  }
  form{
    margin-top: 18px;
    margin-bottom: 18px;
    h4{
      border-top: 2px dashed #EF3F6A;
      padding-top: 18px;
      @include font-size(2.1);
      font-weight: bold;
    }
    h5{
      margin-bottom: 5px;
      @include font-size(1.7);
      line-height: 1.3;
    }
    p{
      margin-bottom: 0;
    }
    label {
      @include font-size(1.7);
      font-weight: normal;
      display: inline-block;
      margin-top: 15px;
    }
    input[type=text], textarea{
      border: 1px solid #CBCBCB;
      width:100%;
      padding:5px;
      //margin-bottom: 15px;
      border-radius: 4px;
      &.error {
        background: none repeat scroll 0 0 #FFeded;
        border: 1px solid #EF3F6A;
        margin-bottom: 0;
      }
    }
    textarea{
      margin-bottom: 10px;
    }
    input[type=submit] {
      @extend .btn;
      @include btn-pseudo-states-gradient(#0A191D, #E2F6FF, #C5EDFF, #FFFFFF, #88DDFF);
      margin-right: 5px;
      margin-top: 25px;
      padding: 10px 22px;
      @include font-size(1.8);
    }
    div#captcha-p {
      position: relative;
      padding-left: 50%;
      @media (min-width: $screen-phone) {
        padding-left: 35%;
      }
      @media (min-width: 568px) {
        padding-left: 29%;
      }
      @media (min-width: $screen-lg-desktop) {
        padding-left: 20%;
      }
      #captcha-img {
        position: absolute;
        height: 55px;
        left:15px;
        top: 18px;
        border: 1px solid #CBCBCB;
        width: auto;
        @media (min-width: $screen-xs) and (max-width: $screen-sm-max) {
          left: 8px;
        }
        @media (min-width: $screen-desktop) {
          height: 60px;
          top: 13px;
        }
      }
      #refresh {
        top: 21px;
        left: 20px;
        position: absolute;
        @media (min-width: $screen-desktop) {
          top: 17px;
        }
      }
    }
  }
}
.modal-body form {
  margin-top: 10px;
  div#captcha-p {
    padding-left: 33%;
  }
}
.modal-title {
  color: #2D2D2D;
  @include font-size(2.4);
  font-weight: bold;
  margin-left: 17px;
}
#uspjesna-poruka {
  background-color: #E1F5FF;
  border-radius: 6px 6px 6px 6px;
  color: #273A76;
  font-weight: normal;
  padding: 15px;
}
.error-box {
  color: #FF8B00;
  font-size: 1.0em;
  font-weight: bold;
  padding: 1% 0;
}

.error-box p{
  margin: 1% 1% 1% 0;
  display:inline-block;
  padding: 0.4% 1% 0.4% 3%;
  background: #FDF5D9 url("#{$image-path}unchecked1.gif") no-repeat scroll 5px 9px;
  border: 1px solid #FF8B00;
  -webkit-border-radius: 0.2em;
  -moz-border-radius: 0.2em;
  border-radius: 0.2em;
}

.kontakt form p.error-message {
  color: #EF3F6A;
  background: url("#{$image-path}unchecked1.gif") no-repeat scroll 5px 9px transparent;
  line-height: 20px;
  padding: 6px 30px;
  display:inline;
}
