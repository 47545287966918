#blog-wrap {
  @include clearfix();
  h2{
    color: #293A6F;
    @include font-size(2.4);
    font-weight: bold;
    padding-bottom:15px;
    border-bottom: 1.5px solid #EF3F6A;
  }
  #novosti {
    background-color: #DEF5FF;
    border: 2px solid #5FCEFF;
    color: #273A76;
    @include font-size(2.1);
    font-weight: bold;
    line-height: 1.4;
    padding: 4px 0 4px 20px;
    border-radius: 5px;
    margin-top: 5px;
  }
  #blog-wrap p#no-post{
    color: #293A6F;
    @include font-size(1.9);
  }
}

.post {
  float: left;
  margin-bottom: 10px;
  padding: 5px 0 25px;
  h3 {
    color: #EF3F6A;
    @include font-size(2.9);
    font-weight: bold;
    margin-bottom:1.5%;
    a{
      color: #EF3F6A;
      @include font-size(2.6);
      //font-weight: bold;
      text-decoration: none;
      &:hover{
        color: #F00;
      }
    }
  }
  .meta {
    float: left;
    margin-bottom: 10px;
    width: 100%;
    > div {
      background-color: #F6FBFF;
      float: left;
      font-family: Arial,Helvetica,sans-serif;
      @include font-size(1.6);
      margin-bottom: 10px;
      margin-right: 10px;
      padding: 2px 5px;
    }
  }
  .intro {
    float: left;
  }
  .post_image img {
    width: 100%;
  }
  .intro-text {
    @include font-size(1.7);
  }
  .opsirnije {
    color: #EF3F6A;
    @include font-size(1.8);
    text-decoration: none;
    background-color: #F6FBFF;
    &:hover {
      color: #F00;
    }
  }
  .opsirnije:hover {
    color: #F00;
  }
  .addthis_toolbox {
    float: left;
    margin-bottom: 15px;
  }
  .body {
    float: left;
    @include font-size(1.7);
    margin-bottom: 15px;
    width: 100%;
  }
}

.right-blog {
  background: url('#{$image-path}right.jpg') no-repeat scroll 15px 0 transparent;
  position:relative;
  margin-bottom: 40px;
  h4{
    @include font-size(1.8);
    font-weight: bold;
    margin-left:12%;
    margin-top:2%;
    width:85%;
    border: 1px solid #EF3F6A;
    background-color: #153039;
    color:#fff;
    padding:3% 0;
    text-align:center;
    border-radius: 6px;
  }
  .archive, .blog_categories {
    margin-bottom: 35px;
    margin-left: 20%;
    ul {
      list-style: none;
      padding-left: 0;
      margin-top: 20px;
      li {
        margin-bottom: 5px;
        a {
          @include font-size(1.9);
          color: #000;
          text-decoration: none;
          &:hover {
            color: #D53232
          };
        }
      }
    }
  }
}