// SIDEBAR

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: transparent;
}

.vista-nav-list{
  background: url('#{$image-path}left1.jpg') no-repeat scroll 100% 0 transparent;
  li.selected a,
  li.selected a:hover {
    background-color: #FFFFFF;
    padding: 16px 15px;
    color: #ED145B;
    @media (min-width: $screen-tablet) {
      padding: 15px 5px;
    }
    @media (min-width: $screen-md) {
      padding: 20px 15px;
    }
  }
}

.vista-nav-list > li > a {
  margin-bottom: -1px; // pull up the following link for a 1px border between
  border-top: 1px solid #e5e5e5;
  padding: 10px 15px;
  @include font-size(1.4);
  line-height: 1.2;
  font-weight: bold;
  color: #175386;
  transition: color 0.3s linear 0s;
  &:hover{
    color: #A4A4A4;
  };
  @media (min-width: $screen-tablet) {
    padding: 10px 5px;
    @include font-size(1.3);
    line-height: 1.42857;
  }
  @media (min-width: $screen-md) {
    padding: 13px 15px;
    @include font-size(1.5);
    line-height: 1.42857;
  }
}

.nav-list > .active > a,
.nav-list > .active > a:hover,
.nav-list > .active > a:focus {
  z-index: 2; // Bring active item forward so border sits on top of next element
  color: #fff;
  background-color: $link-color;
  border-color: $link-color;
}