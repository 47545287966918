// RIGHT

#right {
  background: url('#{$image-path}right.jpg') no-repeat scroll 15px 0 transparent;
  position:relative;
  margin-bottom: 40px;
  h4{
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    margin-left:12%;
    margin-top:2%;
    width:85%;
    border: 1px solid #EF3F6A;
    background-color: #153039;
    color:#fff;
    padding:3% 0;
    text-align:center;
    border-radius: 4px;
  }
  h5{
    font-weight: normal;
    font-size: 16px;
    font-size:1.6rem;
    margin-top:7px;
    @media (min-width: $screen-md) {
      font-size: 18px;
      font-size:1.8rem;
    }
  }
  h5 a{
    color: #293A6F;
  }
  .carousel-lite{
    height: 438px;
    overflow: hidden;
    visibility: visible;
    margin-left:12%;
    width:88%;
    margin-top:24px;
    @media (min-width: $screen-md) {
      margin-top:62px;
    }
    ul {
      list-style-type: none;
      position: relative;
      height: 1638px;
      top: 0;
      z-index: 1;
      margin: 0;
      padding: 0;
      li{
        float: none;
        overflow: hidden;
        width: 100%;
      }
      img {
        width:100%;
      }
    }
  }
}

#right .prev, #right .next {
  width: 38px;
  height:38px;
  display: block;
  cursor: pointer;
  background: url('#{$image-path}arrow_hor_1.png') no-repeat 0 0;
  position: absolute;
  text-indent: -9999px;
  left: 47%;
  z-index:9999;
}

#right .next{
  top: 101.5%;
  background-position: -114px 0;
}

#right .prev{
  top: 10.2%;
  background-position: -76px 0;
}

#right .next:hover{
  background-position: -38px 0;
}

#right .prev:hover{
  background-position: 0 0;
}
