// STICKY FOOTER

$footer-height: 60px;
$footer-offset: 20px;

html{height:100%;}

body{
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin:0 auto;
  position:relative;
}

.footer-offset{padding-bottom: $footer-height + $footer-offset;}

$footer-top-color:       #193843;
$footer-bottom-color:    #050E10;
$footer-border: 1px #5FCEFF solid;

$footer-link-color:                #FCFCFC;
$footer-link-color-hover:          #5FCEFF;

#footer {
  width:100%;
  height:$footer-height;
  border-top: $footer-border;
  background: linear-gradient( to bottom, $footer-top-color, $footer-bottom-color);
  //#gradient .vertical(@footer-top-color, @footer-bottom-color);
  position:absolute;
  bottom:-1px;
  left:0;
  margin-top:-2 * $footer-height;
  p {
    color: $footer-link-color;
    padding-top: 19px;
    margin-bottom:0;
    font-size: 15px;
    a {
      color: $footer-link-color;
      &:hover{
        color: $footer-link-color-hover;
        text-decoration: none;
      }
    }
  }
}