// HEADER 3

#header3 {
  background-color: #C5EDFF;
  padding:5px 0 22px;
  .items{
    padding:10px;
    background-color: #F6FCFF;
    border: 2px solid #5FCEFF;
    border-radius: 8px;
    display: table;
    margin: 10px auto 2px;
    //position: absolute;
    text-align: center;
    width:100%;
    p {
      margin-right: 1.3%;
      margin-bottom: 4px;
      color: #7d7d7d;
      text-shadow: #FFF 0 0 3px;
      @include font-size(1.7);
      @media (min-width: $screen-desktop) {
        @include font-size(1.6);
        margin-right: 1.1%;
      }
      @media (min-width: $screen-lg-desktop) {
        @include font-size(1.9);
      }
      display: inline-block;
      line-height: 1.0;
      span {
        color:green;
        text-shadow: #FF5 0 0 3px;
        margin-right: 6px;
        @include font-size(1.9);
        @media (min-width: $screen-lg-desktop) {
          @include font-size(2.1);
        }
      }
    }
  }
}