// HEADER 2

$header2-top-color:       #E2F6FF;
$header2-bottom-color:    #C5EDFF;
$header2-top-border: 1px #5FCEFF solid;
$header2-bottom-border: 1px solid #5FCEFF;

#header2 {
  background: linear-gradient(to bottom, $header2-top-color, $header2-bottom-color);
  //#gradient .vertical($header2-top-color, $header2-bottom-color);
  border-bottom: $header2-bottom-border;
  #logo{
    margin-top:6px;
    margin-bottom:5px;
    img {
      width:75%;
      @media (min-width: $screen-tablet) {
        width:85%;
      }
      @media screen and (min-width: $screen-desktop) {
        width:65%;
      }
      @media screen and (min-width: $screen-lg-desktop) {
        width:70%;
      }
    }

  }
  #naslov{
    margin-top:5px;
    margin-bottom:5px;
    font-size: 30px;
    font-size: 2.5rem;
    text-align: left;
    color: #293A6F;
    font-weight: bold;
    @media screen and (min-width: $screen-tablet) {
      @include font-size(2.8);
      margin-top:14px;
      text-align: right;
    }
    @media screen and (min-width: $screen-desktop) {
      margin-top:14px;
    }
    @media screen and (min-width: $screen-lg-desktop) {
      margin-top:17px;
    }
  }
}