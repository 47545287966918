//@import "bower_components/susy/sass/susy";
//@import "bower_components/compass-breakpoint/stylesheets/breakpoint";
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "bootstrap3/bootstrap3";

@import "modules/variables-mixins";

@import "modules/sticky-footer";
@import "modules/navbar";
@import "modules/slider";
@import "modules/header1";
@import "modules/header2";
@import "modules/header3";
@import "modules/sidebar";
@import "modules/kontakt";
@import "modules/right";
@import "modules/proizvodi";
@import "modules/detalj";
@import "modules/gdje";
@import "modules/blog";
@import "modules/o-nama";
@import "modules/komentari";
@import "modules/pocetna";


// MAIN REGION

#main-region {
  margin-top: 16px;
  @media (min-width: $screen-tablet) {
    margin-top: 30px;
  }
  .row {
    @media (min-width: $screen-xs) and (max-width: $screen-sm-max) {
      margin-left: -8px;
      margin-right: -8px;
    }
    & > div, form#contact-form{
      @media (min-width: $screen-xs) and (max-width: $screen-sm-max) {
        padding-left:8px;
        padding-right:8px;
      }
    }
  }
}

img.ajax-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -32px; /* -1 * image width / 2 */
  margin-top: -32px;  /* -1 * image height / 2 */
  display: block;
  width: auto;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.subtable > tbody > tr > td{
  padding: 4px 8px;
  line-height: 1.42857;
  vertical-align: top;
}


