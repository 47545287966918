// PROIZVODI

.proizvodi {
  .row {
    & > div {
      @include clearfix();
      margin-bottom: 10px;
      background-color: #DFF5FF;
      padding-top:10px;
      padding-bottom:10px;
      @media (max-width: 479px) {
        width:100%
      }
      @media (min-width: $screen-phone) {
        margin-bottom: 0;
        background-color: #FFF;
      }
      @media (min-width: $screen-tablet) {
        background-color: #FFF;
        padding-top:0;
        padding-bottom:0;
      }
    }
  }
  h3 {
    margin-top: 14px;
    line-height: 0.9;
    float:left;
    max-width: 60%;
    @media (min-width: $screen-phone) {
      margin-top: 5px;
      max-width: 100%;
    }
    @media (min-width: $screen-tablet) {
      margin-top: 8px;
      max-width: 100%;
    }
    a{
      color: #293A6F;
      @include font-size(1.8);
      text-decoration: none;
      @media (min-width: $screen-phone) {
        @include font-size(1.7);
      }
      @media (min-width: $screen-tablet) {
        @include font-size(1.6);
      }
      @media (min-width: $screen-desktop) {
        line-height: 1.1;
        @include font-size(1.8);
      }
      @media (min-width: $screen-lg-desktop) {
        line-height: 1.2;
        @include font-size(1.9);
      }
      &:hover{
        color: #949494;
      };
    }
  }
  .thumbnail {
    width:30%;
    float:left;
    padding:4px;
    margin-right: 10px;
    @media (min-width: $screen-phone) {
      width:100%;
      margin-right: 0;
    }
    @media (min-width: $screen-tablet) {
      width:100%;
      margin-right: 0;
    }
  }
  img {
    width:100%;
  }
}
